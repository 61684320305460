.fida__dropdown {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
}
.fida__dropdown.input__error {
  border-color: red;
}
.fida__dropdown_button {
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}
.fida__dropdown_button_text {
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}
.fida__dropdown_content {
  position: absolute;
  top: 105%;
  left: 0;
  margin: 0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  z-index: 10;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  background-color: #b794f4;
}
.fida__dropdown_item {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #eee;
  text-transform: capitalize;
  user-select: none;
}

.fida__dropdown_item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.fida__dropdown_item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.fida__dropdown_item:hover {
  background-color: #ed64a6;
  color: #fff;
}

.selected_input_field {
  background-color: transparent;
  width: 100%;
}
