.task_card_wrapper {
  background-color: #ffffff;
  padding: 5px 10px;
  box-shadow: 9px 8px 24px rgba(103, 80, 164, 0.2);
  border-radius: 16px;
}
.task_card_wrapper_inner {
  padding: 10px;
}
.task_department_wrapper {
  display: flex;
  padding: 5px 0;
  flex-wrap: wrap;
}

.depart_btn_wrapper {
  border-radius: 20px;
  text-transform: capitalize;
  padding: 4px 8px;
  color: #767676;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
  box-sizing: border-box;
  margin-bottom: 4px;
}
.depart_btn_wrapper.urget_btn {
  background: linear-gradient(100.32deg, #fcf5ff -6.29%, #f5e4ff 88.21%);
  border: 0.5px solid rgba(86, 43, 133, 0.1);
  color: #cc4f5e;
}
.depart_btn_wrapper.btn__icon {
  color: #8b4fcc;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.depart_btn_wrapper.btn__icon svg {
  margin-right: 5px;
  font-size: 16px;
  color: #8b4fcc;
}
.depart_btn_wrapper.primary {
  background-color: rgba(137, 27, 232, 0.27);
}
.depart_btn_wrapper.secondary {
  background-color: rgba(26, 214, 152, 0.3);
}
.depart_btn_wrapper.tertiary {
  background-color: rgba(255, 56, 56, 0.3);
}
.task_content_wrapper {
  padding: 10px 0;
}
.task_content_wrapper h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task_content_wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #929292;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task_actions_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.task_actions_btns {
  display: flex;
}
