.table_header {
  background-color: var(--table_head_bg_color);
  margin-top: 10px;
}
.table_header tr th {
  padding: 10px;
  font-weight: 700;
  color: #000000;
  white-space: nowrap;
  text-transform: uppercase;
}
.subheader_wrapper {
  font-weight: 400;
  font-size: 12px;
}

.table {
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
  padding-bottom: 10px;
  margin: 20px 0;
  overflow-x: auto;
}
.table_actions_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.table_header tr th:last-of-type {
  text-align: center;
}

.table tbody tr td:last-of-type {
  text-align: center;
}
.clickable_row {
  cursor: pointer;
}
.clickable_row:hover td {
  background-color: aliceblue !important;
}
.no_data_wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: aliceblue;
  font-weight: 700;
}
