.table_container {
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 12px;
  margin-bottom: 20px;
}
.table__wrapper {
  border-radius: 20px;
}
.table_header {
  background-color: var(--table_head_bg_color);
}

.table_header th {
  text-transform: capitalize;
  vertical-align: top;
  white-space: nowrap;
  font-weight: 700;
  text-align: center;
}

.table__wrapper td {
  white-space: nowrap;
  text-align: center;
}

.pending_approval,
.canceled,
.approved {
  font-weight: 700;
}

.pending_approval {
  color: #562b85;
}
.approved {
  color: #24ff00;
}
.canceled {
  color: #ff3838;
}
/* NO RECORDS WRAPPER */
.no_records_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #00000085;
}