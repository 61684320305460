.fida_database_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.folder_card_wrapper {
  width: 20%;
  padding: 20px;
  user-select: none;
}
.folder_card_wrapper_inner {
  box-shadow: drop-shadow(10px 10px 85px rgba(0, 0, 0, 0.2));
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.folder_card_wrapper h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #562b85;
  text-transform: uppercase;
  margin-top: 5px;
}
