.attendence_form_wrapper {
  padding: 10px 20px;
}

.input_field_wrapper {
  display: flex;
  align-items: center;
}

.field_row_label {
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 15px;
  margin-bottom: 4px;
}
.input_field_wrapperr {
  align-items: center;
}

.input_field_group {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  margin-bottom: 0!important;
}

.attendence_upload_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
  user-select: none;
}

.file_upload_label {
  margin-right: 8px;
  background: linear-gradient(102.46deg, #b28dda 7.45%, #562b85 98.81%);
  color: #ffffff;
  width: auto;
  padding: 8px;
  border-radius: 12px;
  text-transform: capitalize;
}

.file_upload_area {
  border: 1px solid #000000;
  border-radius: 12px;
  width: 70%;
  cursor: pointer;
  padding: 5px;
}

.form_actions_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
}


/* INPUT GROUP */
.label_wrapper{
  white-space: nowrap;
  padding: 5px 5px;
  background-color: #EDF2F7;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  display: flex;
  align-items: center;
}
.input_group_wrapper{
  display: flex;
  width: 100%;
}