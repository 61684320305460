.pagination-container {
  display: flex;
  list-style-type: none;
  user-select: none;
  justify-content: center;
}
.pagination-item {
  padding: 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: #ffffff;
  background-color: var(--secondary_text_color);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01071em;
  border-radius: 12px;
  line-height: 1.43;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  min-width: 32px;
}

.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-item:hover {
  background-color: var(--primary_color);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: var(--primary_color);
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-item.disabled {
  pointer-events: none;
  background-color: #595959;
}

.arrow:hover {
  background-color: transparent;
  cursor: default;
}

@media screen and (max-width: 680px) {
  .pagination-container {
    flex-wrap: wrap;
    margin: 15px 0 10px -30px;
  }
  .pagination-item {
    margin: 4px;
  }
}
