.gpa__custom_text_field_wrapper {
  width: 100%;
}
.gpa__custom_text_field_result_dropdown {
  position: relative;
  text-align: left;
}

.gpa__custom_text_field_results {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  background-color: #b794f4;
  z-index: 2;
  border-radius: 4px;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}
.gpa__custom_text_field_results li {
  padding: 6px 8px;
  cursor: pointer;
  text-transform: capitalize;
  border-top: 1px solid #e2e8f0;
}

.gpa__custom_text_field_results li:hover {
  background-color: #ed64a6;
  color: #fff;
}
.no_results_wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  margin: 0;
  background-color: #b794f4;
  color: #fff;
  border-radius: 12px;
  padding: 16px 8px;
  box-shadow: 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
