.approvals_table_wrapper {
  background-color: #ffffff;
}
.table_header {
  background-color: var(--table_head_bg_color);
  font-weight: 600;
  color: #000000;
}
.table_header th {
  font-size: 15px;
}
.approvals_table_wrapper td {
  font-weight: 600;
}

.approval_table td:last-child {
  white-space: nowrap;
}

.primary_text_icon {
  display: flex;
  /* align-items: center; */
}
.primary_text_icon svg {
  margin-right: 5px;
}
