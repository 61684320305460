.form_wrapper {
  padding: 20px;
}
.form_btn_title_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}
.form_wrapper h2 {
  font-weight: 700;
  font-size: 34px;
  color: #000000;
}
.field_label {
  color: #4e5d78;
}
.form_action_btn_wrapper {
  padding: 20px 0 10px;
  display: flex;
  justify-content: space-between;
}
.form_action_btn_wrapper.btn_right {
  justify-content: flex-end;
}
.close_modal_wrapper {
  background-color: red;
  padding: 2px;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
}

