.data__purpose_primary_text {
  white-space: nowrap;
}
.notifications_table td {
  padding-left: 10px !important;
}
.body_wrapper {
  width: 400px;
}
.subject_wrapper {
  width: 240px;
}
