.action_taken_wrapper {
  padding: 10px;
}
.card_wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}
.avater_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 2px;
  background-color: #f5dbff;
  border: 1px solid #562b85;
  color: #562b85;
  font-weight: 600;
}
.avater_wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.content_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.content_wrapper h3 {
  font-weight: 500;
}
.delete_btn {
  color: #562b85;
  cursor: pointer;
}
.delete_btn:hover {
  color: red;
}
.add_actions_btn {
  margin-top: 10px;
  text-align: right;
}
