.table_header {
  background-color: #fe00b7;
}
.table_header th {
  text-transform: capitalize;
  vertical-align: top;
  white-space: nowrap;
  border: 1px solid #000000;
  color: #000000;
  font-weight: 700;
}

.table__wrapper td {
  border: 1px solid #000000;
}
