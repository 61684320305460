.table_container {
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: #fff;
}

.leave_actions_wrapper {
  width: 100%;
  padding: 20px;
}
