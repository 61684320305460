.table_wrapper {
  background-color: #ffffff;
  border-radius: 16px;
  padding-bottom: 10px;
}
.table_header {
  background-color: var(--table_head_bg_color);
  font-weight: 600;
  color: #000000;
}
.table_header th {
  font-size: 15px;
}
.table_wrapper td {
  font-weight: 600;
}

.primary_text_icon {
  display: flex;
  align-items: center;
}
.primary_text_icon svg {
  margin-right: 5px;
}

/* NO RECORDS WRAPPER */
.no_records_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #00000085;
}