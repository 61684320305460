.form_wrapper {
  padding: 0 20px;
  color: #000000;
}

/* ========USER DETAILS======== */
.user_image_wrapper {
  display: flex;
  justify-content: center;
  user-select: none;
}
.user_image {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: #6d6c6c;
  position: relative;
  cursor: pointer;
}
.img_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #6d6c6c;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.field_wrapper {
  margin: 8px 0;
  user-select: none;
}
.field_label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.field_wrapper p {
  font-size: 14px;
  color: var(--secondary_text_color);
  margin-bottom: 5px;
}
